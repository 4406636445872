<template>
  <div class="c">
    <div style="box-shadow: 0 0 10px rgba(0,0,0,0.5);width: 1000px;margin: 0 auto;">
      <div class="c-content" id="contract">
        <div class="c-top">
          <img src="../../../assets/baojialogo.jpg" class="imgs" />
          <div class="c-top-title">货物购销合同</div>
          <p class="imgs"></p>
          <div class="img-box">
            <img src="../../../assets/baojiaweixinma.jpg" class="erweima" />
          </div>
        </div>
        <div class="c-row">
          <div class="c-col">供方: {{ orderInfo.sellerEnterpriseName }}</div>
          <div class="c-col">签订日期: {{ time }}</div>
        </div>
        <div class="c-row">
          <div class="c-col">需方: {{ orderInfo.buyerEnterpriseName }}</div>
          <div class="c-col">签订地址: {{ getPosition }}</div>
        </div>
        <div class="c-row">
          <div class="c-col"></div>
          <div class="c-col">合同编号: {{ orderInfo.contractNum || null }}</div>
        </div>
        <div class="c-row">
          根据《中华人民共和国民法典》和有关法律、法规的规定，经双方协商一致,签订本合同。
        </div>
        <div class="c-row">
          一、标的：品牌、品名、型号规格、单位、数量、单价、金额、货期。
        </div>
        <el-table style="margin-top: 30px;" :data="orderInfo.documentItemResultVOList" ref="table" border>
          <!-- :span-method="arraySpanMethod"
          :summary-method="getSummaries"
          show-summary -->
          <el-table-column type="index" label="序号" width="50" align="center" />
          <el-table-column :label="item.name" :prop="item.label" :min-width="100" align="center"
            v-for="(item, index) in columns" :key="index" />
          <lida-table-column label="货期" prop="enterpriseName">
            <template v-slot="scope">
              <span>{{ scope.row.channel ? scope.row.channel : '现货' }}</span>
            </template>
          </lida-table-column>
        </el-table>
        <div class="cell cellList">
          <div class="left">合计（小写）：</div>
          <div class="right">总数量 {{ dataTotal }} 件 ， ￥{{ totalAmount }}</div>
        </div>
        <div class="cell cellList">
          <div class="left">合计（大写）：</div>
          <div class="right">{{ amountInWords }}</div>
        </div>

        <div class="cell">*请仔细核对产品规格型号,数量准确无误后请签字盖章回传!非标产品,不予退换货!货期节假日顺廷*</div>
        <!-- <div class="c-row" style="margin-bottom: 25px;">
          *请仔细核对产品规格型号,数量准确无误后请签字盖章回传!非标产品,不予退换货!货期节假日顺廷*
        </div> -->
        <div class="c-row">
          二、质量要求、技术标准、供方对质量负责的条件和期限：按原厂家标准。
          <!-- <div style="font-size: large;">
            <div
              style="font-size: large;margin-left: -100px;"
              v-if="productAmount != null"
            >
              订单总金额：{{ productAmount }}
            </div>
            <div
              style="font-size: large;margin-left: -100px;"
              v-if="profitPoints != null"
            >
              服务费：{{ profitPoints }}
            </div>
            <div style="font-size: large;margin-left: -100px;">
              最终订单总金额：￥{{ totalAmount }}
            </div>

            <div style="font-size: large;margin-left: -100px;">
              大写：{{ amountInWords }}元
            </div>
          </div> -->
        </div>
        <div class="c-row">三、交(提)货地点：供方送或寄于需方公司所在地。</div>
        <div class="c-row" style="position: relative;">
          <span style="white-space: nowrap;">四、运输方式及到达站港和费用负担：</span>
          <el-input style="line-height: 30px;" v-model="obj.freightPay" placeholder="请输入"
            v-if="!isBuyer && orderInfo.contractStatus !== '2'" />
          <span style="flex: 1;" v-else>{{ obj.freightPay }}</span>
        </div>
        <div class="c-row">
          五、包装标准、包装材料的费用负担：原厂家标准包装。
        </div>
        <div class="c-row">
          六、验收标准、方法及提出异议期限：按原厂家标准验收，如有产品质量问题，
          需在收到货后叁天内提出书面异议，否则视为供方提供的产品符合约定;如系运输原因导致货物损害，
          需方应在运输公司的面单上注明损害程度以及派件人员的签字确认，并于壹天内提出书面反馈，
          否则视为供方提供的产品符合约定，关于售后流程以厂家（所订品牌）为标准，供方需要积极反应，
          并通知厂家进行售后，但厂家处理方式与流程供方无权力也无义务改变，所以不做为需方欠款违约理由。
        </div>
        <div class="c-row">
          <span style="white-space: nowrap;">七、结算方式及期限：</span>
          <el-input style="line-height: 30px;" v-model="obj.settlementPeriod" placeholder="请输入"
            v-if="!isBuyer && orderInfo.contractStatus !== '2'" />
          <span style="flex: 1;" v-else>{{ obj.settlementPeriod }}</span>
        </div>
        <div class="c-row">
          八、违约责任：1、非厂家原因，供方自身原因延迟对需方供货，供方需要向需方支付所拖欠交货同等金额每日百分之一点五(1.5%)违约金。
          2、按双方结算方式，需方延迟向供方支付余款，需方则应就所拖欠的款项部分向供方支付每日百分之一点五(1.5%)的欠款利息。
          3、任意一方违约，守约方为维护自身权益而支出的包括但不限于律师费、差旅费等相关费用，由违约方承担。
        </div>
        <div class="c-row">
          <span style="white-space: nowrap;"> 九、解决合同纠纷的方式：按《中华人民共和国民法典》规定执行，协商、诉讼；诉讼管辖地为 </span>
          <el-input style="line-height: 30px;" v-model="obj.addressPeriod" placeholder=""
            v-if="!isBuyer && orderInfo.contractStatus !== '2'" />
          <span style="flex: 1;" v-else>{{ obj.addressPeriod }}</span>



        </div>
        <div class="c-row">
          十、其他约定事项：1、本合同价格为含税价（13%）；付款方式为转账到供方提供的合法开户银行或通过经供方同意接收的承兑汇票支付，
          其他的货款支付方式一律无效；2、本合同自收到需方预付款生效，合同货期以收到客户预付款到帐起开始核算生效（不含国家法定节假日和相关国家节假日）
          欠款客户此条无效；3、此合同传真件同具法律效力；4、合同内容以打印为准,手写涂改无效；
          5、自合同签订日起超过叁天需方未向供方支付预付款的，供方保留不按原价、原货期供货的权利，若需订货的，需重新确认价格与货期；
          6、本合同所有产品如需提供售后维修服务的,按照供应商检测凭证为依据,若是产品质量原因,由供方免费安排处理,若是客户使用不当原因造成,
          按供应品牌商的收费标准收费；7、未经当事双方许可,甲乙双方都不得向第三方泄露本合同的任何交易信息,若有一方违反,需承担另一方由此而产生的所有损失；
          8、本合同不含现场服务费；9；非产品质量问题不接受退货；10、本合同按型号供货，如产生其他相关参数等问题，以型号判定为准。
        </div>
        <div class="c-footer">
          <div class="c-item">
            <img class="chapter" :src="img" />
            <div class="c-item-title">供方</div>
            <div class="c-row">单位名称：{{ seller.enterpriseName }}</div>
            <div class="c-row">地址：{{ seller.address }}</div>
            <div class="c-row">企业编码：{{ seller.enterpriseCode }}</div>
            <div class="c-row">电话：{{ seller.legalPersonPhone }}</div>
            <div class="c-row">传真：{{ seller.fax }}</div>
            <div class="c-row">开户银行：{{ sell.bankAccount }}</div>
            <div class="c-row">帐号：{{ sell.bankName }}</div>
            <div class="c-row">税号：{{ sell.taxpayerCode }}</div>
            <div class="c-row">
              <span style="white-space: nowrap;">代表： </span>
          <el-input style="line-height: 30px;" v-model="seller.legalPersonName" placeholder=""
            v-if="!isBuyer && orderInfo.contractStatus !== '2'" />
          <span style="flex: 1;" v-else>{{ seller.legalPersonName }}</span>
              
              
               </div>
          </div>
          <div class="c-item">
            <div class="c-item-title">需方</div>
            <div class="c-row">单位名称：{{ buyer.enterpriseName }}</div>
            <div class="c-row">地址：{{ buyer.address }}</div>
            <div class="c-row">企业编码：{{ buyer.enterpriseCode }}</div>
            <div class="c-row">电话：{{ buyer.legalPersonPhone }}</div>
            <div class="c-row">传真：{{ buyer.fax }}</div>
            <div class="c-row">开户银行：{{ buy.bankAccount }}</div>
            <div class="c-row">帐号：{{ buy.bankName }}</div>
            <div class="c-row">税号：{{ buy.taxpayerCode }}</div>
            <div class="c-row">

              <span style="white-space: nowrap;">代表： </span>
          <el-input style="line-height: 30px;" v-model="buyer.legalPersonName" placeholder=""
            v-if="!isBuyer && orderInfo.contractStatus !== '2'" />
          <span style="flex: 1;" v-else>{{ buyer.legalPersonName }}</span>
            </div>
          </div>
        </div>
      </div>
    </div>
    <el-button style="position: fixed;right: 30px;top: 30px;" type="primary" @click="generatePDF">下载pdf</el-button>

    <el-button style="position: fixed;right: 140px;top: 100px;" type="primary" @click="generatePng">下载png</el-button>
    <el-button style="position: fixed;right: 30px;top: 100px;" type="primary" @click="back">返回</el-button>
    <el-button style="position: fixed;right: 140px;top: 30px;" type="success" @click="upContract"
      v-if="!isBuyer && orderInfo.contractStatus !== '2'">上传合同</el-button>
  </div>
</template>

<script>
import html2Canvas from "html2canvas";
import JsPDF from "jspdf";
import { mapActions, mapState } from "vuex";
import moment from "moment";
import { ElMessage } from "element-plus";

export default {
  data() {
    return {
      data: [],
      columns: [
        { name: "品牌", label: "brandName" },
        { name: "品名", label: "productName" },
        { name: "规格", label: "articleNo" },
        { name: "型号", label: "barCode" },
        { name: "数量", label: "purchaseQty" },
        { name: "单价", label: "purchasePrice" },
        { name: "金额", label: "totalAmount" },
      ],
      orderInfo: {}, //订单信息
      seller: {}, //供方信息
      buyer: {}, //需方信息
      buy: {},
      sell: {},
      obj: {
        freightPay: null,
        settlementPeriod: null,
        addressPeriod: null
      },
      img: null,
      amountInWords: "",
      totalAmount: "",
      productAmount: "",
      profitPoints: "",
    };
  },
  computed: {
    ...mapState(["position"]),
    ...mapState(["userInfo"]),
    time() {
      return this.orderInfo.contractDate || moment().format("YYYY-MM-DD");
    },
    getPosition() {
      return (
        this.orderInfo.contractSignAddress ||
        (this.position.length ? this.position.join("-") : "未知")
      );
    },
    dataTotal() {
      return this.orderInfo.documentItemResultVOList ? this.orderInfo.documentItemResultVOList.reduce((pre, cur) => {
        return pre + cur.purchaseQty * 1
      }, 0) : 0
    },
    isBuyer() {
      //判断是不是买家
      return this.userInfo.userId === this.orderInfo.buyerId;
    },
  },
  methods: {
    ...mapActions("mysaleMudule", [
      "purchaseDetails", //获取订单详情
      "purchaseUpdateContractFile", //修改采购单收货地址
      "getUserInvoiceUserId",
    ]),
    ...mapActions("myMessageModule", [
      "getEnterprise", //获取企业信息详情
    ]),
    generatePng() {
      var title = "货物购销合同";
      var name = this.orderInfo.sellerEnterpriseName;
      html2Canvas(document.querySelector("#contract"), {
        allowTaint: true,
      }).then(function (canvas) {
        let ctx = canvas.getContext("2d");
        //处理文字间的间距
        var text = "";
        for (var i = 0; i < 20; i++) {
          text += name + "              ";
        }
        //生成水印
        for (let i = 0; i < 400; i++) {
          ctx.rotate((-45 * Math.PI) / 180); // 水印初始偏转角度
          ctx.font = "14px";
          ctx.fillStyle = "rgba(0,0,0,0.3)";
          ctx.fillText(text, -600, i * 100);
          ctx.rotate((45 * Math.PI) / 180); // 把水印偏转角度调整为原来的，不然他会一直转
        }
        let contentWidth = canvas.width;
        let contentHeight = canvas.height;
        let pageHeight = (contentWidth / 592.28) * 841.89;
        let leftHeight = contentHeight;
        let position = 0;
        let imgWidth = 595.28;
        let imgHeight = (592.28 / contentWidth) * contentHeight;
        let pageData = canvas.toDataURL("image/png", 1.0);
        var dlLink = document.createElement('a');
        dlLink.download = title;
        dlLink.href = pageData;
        dlLink.dataset.downloadurl = ['image/png', dlLink.download, dlLink.href].join(':');
        document.body.appendChild(dlLink);
        dlLink.click();
        document.body.removeChild(dlLink);

 

      });
    },
    generatePDF() {
      var title = "货物购销合同";
      var name = this.orderInfo.sellerEnterpriseName;
      html2Canvas(document.querySelector("#contract"), {
        allowTaint: true,
      }).then(function (canvas) {
        let ctx = canvas.getContext("2d");
        //处理文字间的间距
        var text = "";
        for (var i = 0; i < 20; i++) {
          text += name + "              ";
        }
        //生成水印
        for (let i = 0; i < 400; i++) {
          ctx.rotate((-45 * Math.PI) / 180); // 水印初始偏转角度
          ctx.font = "14px";
          ctx.fillStyle = "rgba(0,0,0,0.3)";
          ctx.fillText(text, -600, i * 100);
          ctx.rotate((45 * Math.PI) / 180); // 把水印偏转角度调整为原来的，不然他会一直转
        }
        let contentWidth = canvas.width;
        let contentHeight = canvas.height;
        let pageHeight = (contentWidth / 592.28) * 841.89;
        let leftHeight = contentHeight;
        let position = 0;
        let imgWidth = 595.28;
        let imgHeight = (592.28 / contentWidth) * contentHeight;
        let pageData = canvas.toDataURL("image/jpeg", 1.0);
        console.log(pageData);
        let PDF = new JsPDF("", "pt", "a4");
        if (leftHeight < pageHeight) {
          PDF.addImage(pageData, "JPEG", 0, 0, imgWidth, imgHeight);
        } else {
          while (leftHeight > 0) {
            PDF.addImage(pageData, "JPEG", 0, position, imgWidth, imgHeight);
            leftHeight -= pageHeight;
            position -= 841.89;
            if (leftHeight > 0) {
              PDF.addPage();
            }
          }
        }
        PDF.save(title + ".pdf");
      });
    },
    // arraySpanMethod() {
    //   //table合计行合并单元格
    //   setTimeout(() => {
    //     if (this.$refs.table.$el) {
    //       let current = this.$refs.table.$el
    //         .querySelector(".el-table__footer-wrapper")
    //         .querySelector(".el-table__footer");
    //       let cell = current.rows[0].cells;
    //       //cell[1].style.display = 'none'
    // 	  cell[0].colSpan = "1";
    //       cell[1].colSpan = "2";
    // 	  cell[2].colSpan = "2";
    // 	  cell[3].colSpan = "2";
    // 	  cell[4].colSpan = "2";
    //     }
    //   }, 50);
    // },
    // getSummaries(param) {
    //   const { columns, data } = param;
    //     const sums = [];
    //     columns.forEach((column, index) => {
    //       if (index === 0) {
    //         sums[index] = "合计";
    //         return;
    //       }else if(index === 1){
    // 		sums[index] = '（小写）'
    // 	  }else if(index === 2){
    // 		sums[index] = this.totalAmount
    // 	  }else if(index === 3){
    // 		sums[index] = '（大写）'
    // 	  }else{
    // 		sums[index] = this.amountInWords;
    // 	  }
    // 	})

    //     return sums;
    // },
    getOrderInfo() {
      //获取订单详情
      this.purchaseDetails(this.$route.query.id).then((res) => {
        let { code, data } = res.data;
        this.totalAmount = data.totalAmount;
        this.amountInWords = this.convertCurrency(data.totalAmount);
        this.profitPoints = data.profitPoints;
        this.productAmount = data.productAmount;

        console.log(data.buyerId);
        this.getUserInvoiceUserId({ userId: data.buyerId }).then((res) => {
          // console.log(res);
          this.buy = res.data.data;
        });
        this.getUserInvoiceUserId({ userId: data.sellerId }).then((res) => {
          // console.log(res.data.data);
          this.sell = res.data.data;
        });
        if (code === "0") {
          data.contractFiles = JSON.parse(data.contractFiles || "[]");
          data.backContractFiles = JSON.parse(data.backContractFiles || "[]");
          data.payFiles = JSON.parse(data.payFiles || "[]"); //支付凭证
          this.orderInfo = data;
          console.log(this.orderInfo.documentItemResultVOList,'-----')
          this.getQy(data.sellerEnterpriseId, "seller");
          this.getQy(data.buyerEnterpriseId, "buyer");
          this.obj = {
            freightPay: data.freightPay,
            settlementPeriod: data.settlementPeriod,
            addressPeriod: '供货方所在地'
          };
        }
      });
    },
    getQy(id, type) {
      //获取企业信息
      this.getEnterprise(id).then((res) => {
        let { code, data } = res.data;
        // getUserInvoiceUserId
        // console.log(res);
        if (code === "0") {
          this[type] = data;
          if (type === "seller") {
            if (data.sealFlies) {
              data.sealFlies = JSON.parse(data.sealFlies);
              if (data.sealFlies.length) {
                this.getBas64(data.sealFlies[0].url).then((img) => {
                  this.img = img;
                });
              }
            }
          }
        }
      });
    },
    getBas64(url, outputFormat = "image/png") {
      return new Promise(function (resolve, reject) {
        let canvas = document.createElement("CANVAS"),
          ctx = canvas.getContext("2d"),
          img = new Image();
        img.crossOrigin = "Anonymous"; // 重点！设置image对象可跨域请求
        img.onload = function () {
          canvas.height = img.height;
          canvas.width = img.width;
          ctx.drawImage(img, 0, 0);
          let dataURL = canvas.toDataURL(outputFormat);
          canvas = null;
          resolve(dataURL);
        };
        // img.src = url; // 旧的方式
        img.src = url + "?t=" + new Date().valueOf(); // 防止oss的缓存问题
      });
    },
    upContract() {
      //上传合同
      if (!this.obj.freightPay) {
        return ElMessage.warning("请输入运输方式及到达站港和费用负担方");
      }
      if (!this.obj.settlementPeriod) {
        return ElMessage.warning("请输入结算方式及期限");
      }
      this.purchaseUpdateContractFile({
        documentId: this.$route.query.id,
        contractSignAddress: this.getPosition,
        contractDate: this.time,
        freightPay: this.obj.freightPay,
        settlementPeriod: this.obj.settlementPeriod,
      }).then((res) => {
        let { code, data } = res.data;
        if (code === "0") {
          ElMessage.success("操作成功");
          //刷新页面
          this.getOrderInfo();
        }
      });
    },
    back() {
      this.$router.back();
    },
    convertCurrency(money) {
      //汉字的数字
      const cnNums = new Array(
        "零",
        "壹",
        "贰",
        "叁",
        "肆",
        "伍",
        "陆",
        "柒",
        "捌",
        "玖"
      );
      //基本单位
      const cnIntRadice = new Array("", "拾", "佰", "仟");
      //对应整数部分扩展单位
      const cnIntUnits = new Array("", "万", "亿", "兆");
      //对应小数部分单位
      const cnDecUnits = new Array("角", "分", "毫", "厘");
      //整数金额时后面跟的字符
      let cnInteger = "整";
      //整型完以后的单位
      let cnIntLast = "元";
      //最大处理的数字
      let maxNum = 999999999999999.9999;
      //金额整数部分
      let integerNum;
      //金额小数部分
      let decimalNum;
      //输出的中文金额字符串
      let chineseStr = "";
      //分离金额后用的数组，预定义
      let parts;
      if (money === "") {
        return "";
      }
      money = parseFloat(money);
      if (money >= maxNum) {
        //超出最大处理数字
        return "";
      }
      if (money === 0) {
        chineseStr = cnNums[0] + cnIntLast + cnInteger;
        return chineseStr;
      }
      //转换为字符串
      money = money.toString();
      if (money.indexOf(".") === -1) {
        integerNum = money;
        decimalNum = "";
      } else {
        parts = money.split(".");
        integerNum = parts[0];
        decimalNum = parts[1].substr(0, 4);
      }
      //获取整型部分转换
      if (parseInt(integerNum, 10) > 0) {
        let zeroCount = 0;
        const IntLen = integerNum.length;
        for (var i = 0; i < IntLen; i++) {
          const n = integerNum.substr(i, 1);
          const p = IntLen - i - 1;
          const q = p / 4;
          const m = p % 4;
          if (n === "0") {
            zeroCount++;
          } else {
            if (zeroCount > 0) {
              chineseStr += cnNums[0];
            }
            //归零
            zeroCount = 0;
            chineseStr += cnNums[parseInt(n)] + cnIntRadice[m];
          }
          if (m === 0 && zeroCount < 4) {
            chineseStr += cnIntUnits[q];
          }
        }
        chineseStr += cnIntLast;
      }
      //小数部分
      if (decimalNum != "") {
        const decLen = decimalNum.length;
        for (var i = 0; i < decLen; i++) {
          var n = decimalNum.substr(i, 1);
          if (n != "0") {
            chineseStr += cnNums[Number(n)] + cnDecUnits[i];
          }
        }
      }
      if (chineseStr === "") {
        chineseStr += cnNums[0] + cnIntLast + cnInteger;
      } else if (decimalNum === "") {
        chineseStr += cnInteger;
      }
      return chineseStr;
    },
  },
  created() {
    this.getOrderInfo();
  },
};
</script>

<style lang="less" scoped>
.c {
  background: #525659;
  width: 100%;
  height: 100%;
  padding: 30px;
  box-sizing: border-box;
  overflow-y: auto;

  /deep/ .el-input__inner {
    border: none !important;
    padding-left: 0 !important;
    background: none !important;
  }

  /deep/ .has-gutter {
    th,
    td {
      padding: 0 !important;

      .cell {
        display: flex;
        align-items: center;
        justify-content: center;
        height: 45px !important;
        white-space: nowrap;
      }
    }
  }

  .c-content {
    background: #ffffff !important;
    width: 1000px;
    margin: 0 auto;
    padding: 20px;
    box-sizing: border-box;
    position: relative;
    z-index: 1000;

    .c-top {
      display: flex;
      position: relative;
      justify-content: space-between;

      .c-top-title {
        flex: 1;
        text-align: center;
        font-size: 30px;
        font-weight: bold;
        line-height: 50px;
      }

      .imgs {
        width: 200px;
        height: 100px;
      }

      .img-box {
        display: flex;
        position: absolute;
        right: 0;
        top: 0;

        .mingpian {
          width: 180px;
          height: 100px;
          margin-right: 10px;
        }

        .erweima {
          width: 100px;
          height: 100px;
        }
      }
    }

    .c-row {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding-top: 5px;

      .c-col {
        width: 30%;
      }
    }

    .c-footer {
      display: flex;
      align-items: center;
      padding-top: 30px;
      position: relative;

      .c-item {
        flex: 1;
        position: relative;

        .chapter {
          position: absolute;
          width: 300px;
          left: 45%;
          top: -20%;
          transform: translateX(-50%);
          z-index: -1;
        }
      }
    }

    .el-table {
      /deep/ .cell {
        padding: 12px 10px;
      }

      /deep/ .el-table__cell {
        padding: 0;
      }
    }
  }
}

.cell {
  border: 1px solid #ebeef5;
  height: 35px;
  line-height: 35px;
  border-top: none;
  color: #606266;
  text-align: center
}

.cellList {
  display: flex;

  .left {
    width: 50%;
    border-right: 1px solid #ebeef5;
  }

  .right {
    width: 50%;
  }
}
</style>
